import { useState } from "react";
import { BasePlace } from "common/types.ts";
import { MobileSheet, Modal } from "common/components/base";
import PlaceSelection from "common/components/AddBusiness/PlaceSelection.tsx";

import { useTwBreakpoint } from "common/hooks";
import usePlaceMutations from "common/datahooks/usePlaceMutations.tsx";
import { HTTPError } from "common/helpers/HTTP.ts";
import PaymentDetails from "common/components/AddBusiness/PaymentDetails.tsx";
import { clsx } from "clsx";
import Success from "common/components/AddBusiness/Success.tsx";
import { useNavigate } from "@tanstack/react-router";

export function AddBusiness({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) {
  const navigate = useNavigate();
  const isDesktop = useTwBreakpoint("lg");
  const { addPlace, isAddingPlace } = usePlaceMutations();
  const [selectedPlace, setSelectedPlace] = useState<BasePlace | null>(null);
  const [currentStep, setCurrentStep] = useState<
    "place" | "payment" | "success"
  >("place");
  const [, setIsDuplicateError] = useState(false);

  function resetState() {
    setSelectedPlace(null);
    setCurrentStep("place");
  }
  function onConfirm(paymentSourceId: string) {
    if (selectedPlace) {
      addPlace({
        placeName: selectedPlace.name,
        placeId: selectedPlace.id,
        paymentSourceId,
      })
        .then(() => setCurrentStep("success"))
        .catch((error) => {
          if (error instanceof HTTPError && error.code === 409) {
            setIsDuplicateError(true);
          }
        });
    }
  }
  function renderContent({ close }: { close: () => void }) {
    if (currentStep === "place") {
      return (
        <PlaceSelection
          closeDialog={close}
          selectedPlace={selectedPlace}
          setSelectedPlace={setSelectedPlace}
          goToNextStep={() => setCurrentStep("payment")}
        />
      );
    } else if (currentStep === "payment") {
      return <PaymentDetails onConfirm={onConfirm} isLoading={isAddingPlace} />;
    } else {
      return (
        <Success
          onClose={() => {
            if (selectedPlace) {
              navigate({
                to: "/$placeId/home",
                params: { placeId: selectedPlace.id },
              });
            }
            close();
          }}
        />
      );
    }
  }
  if (isDesktop) {
    return (
      <Modal
        variant="white"
        onAfterClose={resetState}
        className={clsx(
          "flex-col",
          currentStep === "payment" ? "lg:w-[880px]" : "lg:w-[510px]",
        )}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        {renderContent}
      </Modal>
    );
  }
  return (
    <MobileSheet isOpen={isOpen} setIsOpen={setIsOpen}>
      {renderContent}
    </MobileSheet>
  );
}
