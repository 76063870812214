import { useEffect, useRef, useState } from "react";
import { Keyword } from "common/types.ts";
import {
  Button,
  DialogHeading,
  Modal,
  SearchField,
} from "common/components/base";
import ViewKeywords from "./ViewKeywords.tsx";
import AddKeywords from "@/src/pages/home/ManageKeywords/AddKeywords.tsx";
import { useAppStore, useTwBreakpoint } from "common/hooks";
import Success from "./Success.tsx";

export function ManageKeywords({
  isOpen,
  setIsOpen,
  keywords,
  maxKeywords,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  keywords: Keyword[];
  maxKeywords: number;
}) {
  const isDesktopOrTablet = useTwBreakpoint("md");
  const [searchText, setSearchText] = useState("");
  const [selectedTab, setSelectedTab] = useState<"view" | "add">("view");
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  //the keywords that the user had when the dialog was opened
  const previousKeywordsRef = useRef<Keyword[]>([]);

  function onUpgrade() {
    setIsOpen(false);
    useAppStore.getState().setIsPlanCustomizationOpen(true);
  }

  useEffect(() => {
    setSearchText("");
  }, [selectedTab]);

  useEffect(() => {
    if (isOpen) {
      previousKeywordsRef.current = keywords;
    }
  }, [isOpen]);

  const filteredKeywords = keywords.filter(({ label }) => {
    return searchText ? label.includes(searchText) : true;
  });

  return (
    <>
      <Modal
        variant="grey"
        className="flex-col lg:h-[638px] lg:w-[1000px] lg:pb-8"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onAfterClose={() => {
          setSelectedTab("view");

          if (
            keywords.some(
              ({ id }) =>
                !previousKeywordsRef.current.find(
                  (previousKeyword) => previousKeyword.id === id,
                ),
            )
          ) {
            setIsSuccessOpen(true);
          }
        }}
      >
        {({ close }) => (
          <>
            <DialogHeading className="mx-auto mb-4 py-1.5 text-2xl font-medium md:hidden">
              Keyword manager
            </DialogHeading>
            <div className="mb-5 flex gap-x-3 max-md:px-2">
              <Button
                variant={selectedTab === "view" ? "black" : "transparent"}
                onPress={() => setSelectedTab("view")}
                size={isDesktopOrTablet ? "lg" : "md"}
              >
                My keywords
              </Button>
              <Button
                variant={selectedTab === "add" ? "black" : "transparent"}
                onPress={() => setSelectedTab("add")}
                size={isDesktopOrTablet ? "lg" : "md"}
              >
                Add new keywords
              </Button>
            </div>
            <div className="mb-5 flex flex-col gap-2 max-md:px-3 md:flex-row md:items-center md:justify-between">
              <SearchField
                size="lg"
                value={searchText}
                onChange={(value) => setSearchText(value)}
                placeholder="Search keyword"
              />
              <div className="flex items-center max-md:ml-auto">
                <span className="mr-0.5 text-xs font-medium text-grey-900">
                  {maxKeywords - keywords.length + 1}
                </span>
                <span className="mr-4 text-xs text-grey-600">
                  /{maxKeywords} left
                </span>
                <Button variant="purple" size="sm" onPress={onUpgrade}>
                  Upgrade
                </Button>
              </div>
            </div>
            <div className="-mx-2 flex flex-1 flex-col overflow-hidden rounded-5xl bg-white pt-2 md:-mx-6 md:-mb-8 md:rounded-7xl md:px-3 md:py-6">
              {selectedTab === "add" ? (
                <AddKeywords
                  searchText={searchText}
                  keywords={keywords}
                  maxKeywords={maxKeywords}
                  closeModal={close}
                />
              ) : (
                <ViewKeywords keywords={filteredKeywords} closeModal={close} />
              )}
            </div>
          </>
        )}
      </Modal>
      <Success isOpen={isSuccessOpen} onClose={() => setIsSuccessOpen(false)} />
    </>
  );
}
