import {
  Button,
  DialogHeading,
  MobileSheet,
  Modal,
} from "common/components/base";
import { useTwBreakpoint } from "common/hooks";
import NoKeywordDataImage from "@/src/assets/empty-placeholder/empty-keyword.svg?react";

export default function Success({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const isDesktop = useTwBreakpoint("lg");

  const Component = isDesktop ? Modal : MobileSheet;

  return (
    <Component
      variant="white"
      className="flex-col lg:w-[510px]"
      isOpen={isOpen}
      setIsOpen={(isOpen) => {
        if (!isOpen) {
          onClose();
        }
      }}
    >
      <NoKeywordDataImage className="mb-4 mt-10 h-48 lg:h-60" />
      <DialogHeading className="mb-2 text-center text-2xl font-semibold">
        Active keywords updated
      </DialogHeading>
      <p className="mb-6 text-center text-grey-500">
        Data for your newly added keywords will load on your next working day
      </p>
      <Button variant="black" className="ml-auto" size="md" onPress={onClose}>
        Back to dashboard
      </Button>
    </Component>
  );
}
