import {
  createFileRoute,
  useNavigate,
  useParams,
} from "@tanstack/react-router";
import {
  Badge,
  Button,
  Calendar,
  Popover,
  SelectButton,
  MobileSheet,
  PopoverDialog,
} from "common/components/base";

import Cars from "fontawesome/solid/cars.svg?react";
import CompactDisc from "fontawesome/solid/compact-disc.svg?react";
import Clock from "fontawesome/solid/clock.svg?react";
import Sliders from "fontawesome/solid/sliders.svg?react";
import AllDrives from "@/src/pages/drives/components/AllDrives.tsx";
import { DialogTrigger } from "react-aria-components";
import { GridAndDrivesSettings } from "common/components/GridAndDrivesSettings";
import { parseDate } from "@internationalized/date";
import { useTwBreakpoint } from "common/hooks";
import { formatDate, metersToFeet } from "common/utils.ts";
import usePlace from "common/datahooks/usePlace.tsx";
import useCustomer from "common/datahooks/useCustomer.ts";

type SearchParams = {
  date?: string;
  page: number;
};
export const Route = createFileRoute("/$placeId/_layout/drives")({
  component: Drives,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    return {
      date: search.date as string,
      page: search.page ? (search.page as number) : 0,
    };
  },
  staticData: {
    pageTitle: "Drives",
  },
});
function Drives() {
  const { placeId } = useParams({ from: "/$placeId" });
  const isDesktop = useTwBreakpoint("lg");
  const isDesktopOrTablet = useTwBreakpoint("md");
  const navigate = useNavigate({ from: "/$placeId/drives" });
  const { date } = Route.useSearch();
  const { place } = usePlace(placeId);
  const { customer } = useCustomer();

  const calendarDate = date ? parseDate(date) : undefined;

  const titleComponent = (
    <h3 className="text-lg font-semibold lg:text-xl">Recent drives</h3>
  );
  const badgesComponent = (
    <div className="flex flex-wrap gap-1">
      {place ? (
        <>
          <Badge variant="grey" size="md">
            <Cars />
            Daily drives: {place.settings.drives}
          </Badge>
          {customer && (
            <Badge variant="grey" size="md">
              <CompactDisc />
              Radius:{" "}
              {customer.preferred_unit === "Feet"
                ? `${metersToFeet(place.settings.drives_radius)}ft`
                : `${place.settings.drives_radius}m`}
            </Badge>
          )}
          <Badge variant="grey" size="md">
            <Clock />
            Drives working hours: {place.settings.drives_working_hours}
          </Badge>
        </>
      ) : (
        <>
          <Badge variant="skeleton" size="md" />
          <Badge variant="skeleton" size="md" />
          <Badge variant="skeleton" size="md" />
        </>
      )}
    </div>
  );
  const manageDrivesComponent = (
    <GridAndDrivesSettings
      initialTab="drives"
      renderTriggerComponent={(onPress) => (
        <Button
          variant="black"
          size="lg"
          onPress={onPress}
          isIconOnly={!isDesktop}
        >
          {isDesktop && "Manage drives"}
          <Sliders />
        </Button>
      )}
    />
  );
  function renderDateSelectContent({ close }: { close: () => void }) {
    return (
      <Calendar
        onApply={(date) => {
          navigate({
            search: { date, page: 0 },
          });
          close();
        }}
        onCancel={close}
        initialSelectedDate={calendarDate}
      />
    );
  }
  const dateSelectComponent = (
    <DialogTrigger>
      <SelectButton
        size="lg"
        placeholder="Filter date"
        onClear={
          date
            ? () =>
                navigate({
                  search: (prevSearch) => ({
                    ...prevSearch,
                    date: undefined,
                  }),
                })
            : undefined
        }
        className="capitalize max-lg:w-full lg:w-56"
      >
        {calendarDate && formatDate(calendarDate, "short", false)}
      </SelectButton>
      {isDesktopOrTablet ? (
        <Popover>
          <PopoverDialog className="p-4">
            {renderDateSelectContent}
          </PopoverDialog>
        </Popover>
      ) : (
        <MobileSheet>{renderDateSelectContent}</MobileSheet>
      )}
    </DialogTrigger>
  );

  return (
    <div className="flex grow flex-col rounded-4xl bg-grey-50 lg:rounded-7xl">
      {isDesktop ? (
        <div className="flex items-center justify-between p-6 pb-5">
          <div className="flex flex-col gap-y-1">
            {titleComponent}
            {badgesComponent}
          </div>
          <div className="flex gap-x-2">
            {dateSelectComponent}
            {manageDrivesComponent}
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-y-2 p-3">
          <div className="flex justify-between">
            {titleComponent}
            {manageDrivesComponent}
          </div>
          {badgesComponent}
          {dateSelectComponent}
        </div>
      )}
      <AllDrives />
    </div>
  );
}
