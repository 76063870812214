import { JSX } from "react";
import useKeywordsMutations from "common/datahooks/useKeywordsMutations.tsx";
import { useNavigate, useParams, useSearch } from "@tanstack/react-router";
import usePlace from "common/datahooks/usePlace.tsx";

export default function DeleteKeywordButton({
  keywordId,
  renderDeleteButton,
}: {
  keywordId: string;
  renderDeleteButton: (onPress: () => void) => JSX.Element;
}) {
  const { placeId } = useParams({ from: "/$placeId" });
  const { selectedKeywordId } = useSearch({ from: "/$placeId/_layout/home" });
  const navigate = useNavigate({ from: "/$placeId/home" });
  const { removeKeyword } = useKeywordsMutations();
  const { place } = usePlace(placeId);

  function onRemove() {
    removeKeyword({ keywordId }).then(() => {
      //navigate to the default keyword if the deleted keyword is selected, to avoid empty keyword selection
      if (selectedKeywordId === keywordId && place) {
        const defaultKeyword = place.keywords.find(
          ({ default: isDefault }) => isDefault,
        );
        if (defaultKeyword) {
          navigate({ search: { selectedKeywordId: defaultKeyword.id } });
        }
      }
    });
  }
  return renderDeleteButton(onRemove);
}
